<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills>
            <b-nav-item :to="`/accounts/${$route.params.id}`" exact exact-active-class="active">Account</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/controls`" exact exact-active-class="active">Controls</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
    </b-card-header>
    <b-card-body v-if="enrollments">
        <b-table id="table" outlined hover :items="enrollments.items" :fields="fields" primary-key="id" sort-by="label" :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/enrollments/${item.id}`)" class="mb-0" show-empty empty-text="This account has no factors." empty-filtered-text="No factors match your search. If more factors are available you can load them by clicking 'Load More' below.">
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'enrollments')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="enrollments" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? enrollments.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="enrollments?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'enrollments')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="enrollments?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ enrollments.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'factor',
        label: 'Factor',
        sortable: true,
        formatter: 'label'
    },
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'score',
        label: 'Score',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'secret:id': 'Username',
    'secret:password': 'Password',
    'otp': 'One-Time Password',
    'totp': 'Authenticator App',
    'jwt:bearer': 'Personal Token',
    'jwt:spki': 'Private Key',
    'jwt:jwks': 'Hosted Key Set',
    'oauth2:quasr': 'Quasr',
    'oauth2:apple': 'Apple',
    'oauth2:slack': 'Slack',
    'oauth2:github': 'GitHub',
    'oauth2:google': 'Google',
    'oauth2:discord': 'Discord',
    'oauth2:linkedin': 'LinkedIn',
    'oauth2:facebook': 'Facebook',
    'oauth2:microsoft': 'Microsoft',
    'oauth2:oidc': 'OpenID Connect'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Enrollments',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        enrollments: Array
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.enrollments) {
            this.$emit('load', 'enrollments', false);
        }
        if (!this.$store.state.factors) {
            this.$emit('load', 'factors', true); // LOAD ALL
        } else if (this.$store.state.factors.nextToken) {
            this.$emit('next', 'factors', true); // LOAD ALL
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * UTILITIES
         */
        label(value) {
            return this.$store.state.factors?.items.find(factor => factor.id === value)?.label;
        }
    }
}
</script>
<template>
    <b-card-group deck>

        <!-- FACTOR -->
        <b-card v-if="$store.state.factors && $store.state.factors.items.length < 2" class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/play.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Getting Started</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Get started by creating a factor. A factor provides your users / clients with a method to login and signup.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" v-on:click="$emit('show', 'create-factor', { config: {} })" variant="success">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>

        <!-- ACCOUNT -->
        <b-card v-if="$store.state.accounts && $store.state.accounts.items.length < 5" class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/play.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Getting Started</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Get started by creating an account. An account can be a user or a client (application) that needs API access.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" v-on:click="$emit('show', 'create-account', { config: {} })" variant="success">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>
        
        <!-- METRICS (MAA) -->
        <b-card v-if="$store.state.tenant?.metrics" class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly Active Accounts</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>For {{ $store.state.tenant.subscription}}</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is the number of active accounts.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1>{{ $store.state.tenant.metrics.maa }}</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.metrics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- METRICS (MAC) -->
        <b-card v-if="$store.state.tenant?.metrics" class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly API Calls</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>For {{ $store.state.tenant.subscription}}</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is the number of API calls.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1>{{ $store.state.tenant.metrics.mac }}</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.metrics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- DOCUMENTATION -->
        <b-card class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/documentation.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our Docs</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our product documentation to better understand certain concepts as well as how to realise all your projects.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://docs.quasr.io" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://gitbook.com" target="_blank">GitBook</a></small>
            </b-card-footer>
        </b-card>

        <!-- API -->
        <b-card class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our API</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our API collection. We have an Authentication API which is REST, and a Management API which is GraphQL.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://documenter.getpostman.com/view/18129777/UVXgMHEv" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://postman.com" target="_blank">Postman</a></small>
            </b-card-footer>
        </b-card>

        <!-- COMMUNITY -->
        <b-card class="shadow rounded bg-white mb-4" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/community.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Join Our Community</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Join our community for free support, announcements, release notes, peer interaction or to provide us feedback.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://discord.com/channels/895325971278856292/895413575491936257" target="_blank" variant="outline-primary">Join</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://discord.com" target="_blank">Discord</a></small>
            </b-card-footer>
        </b-card>

    </b-card-group>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'Dashboard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'next' ],

    /**
     * DATA
     */
    data() {
        return {
            // TODO
        }
    },

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.tenant) {
            this.$emit('load', 'tenant');
        }
        if (!this.$store.state.factors) {
            this.$emit('load', 'factors', true); // LOAD ALL
        } else if (this.$store.state.factors.nextToken) {
            this.$emit('next', 'factors', true); // LOAD ALL
        }
        if (!this.$store.state.accounts) {
            this.$emit('load', 'accounts');
        }
    },

    /**
     * METHODS
     */
    methods: {

    }
}
</script>
<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/factors.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Factor</h3>
            </b-card-header>
            <b-card-body v-if="enrollment">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="enrollment.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="enrollment.account" readonly></b-form-input>
                                <b-badge v-if="enrollment.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${enrollment.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="enrollment.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="enrollment.subtype" :options="subtypes" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.factors?.items">
                        <b-form-group label="Factor" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-select v-model="enrollment.factor" :options="$store.state.factors.items" value-field="id" text-field="label" disabled></b-form-select>
                                <b-input-group-append>
                                    <b-button :to="`/factors/${enrollment.factor}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="enrollment.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Score" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="enrollment.score" type="number" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="enrollment?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body>
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'input')">
                                    <b-img src="/img/icons/input.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Input</h5>
                                </b-card-header>
                                <b-collapse id="input" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4" v-if="enrollment.config.regex">
                                                <b-form-group label="Regex" label-align-sm="right" label-cols-sm="3" description="This is the regex to which the input must match.">
                                                    <b-form-input v-model="enrollment.config.regex" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4" v-if="enrollment.config.case_sensitive !== null">
                                                <b-form-group label="Case Sensitive" label-align-sm="right" label-cols-sm="3" description="This indicates whether the input is case sensitive.">
                                                    <b-form-checkbox v-model="enrollment.config.case_sensitive" switch disabled></b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="enrollment.subtype === 'totp'">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'totp')">
                                    <b-img src="/img/factors/totp.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Authenticator App</h5>
                                </b-card-header>
                                <b-collapse id="totp" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Digits" label-align-sm="right" label-cols-sm="3" description="This is the amount of digits for a one-time code.">
                                                    <b-form-input v-model="enrollment.config.digits" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Step" label-align-sm="right" label-cols-sm="3" description="This is the time in seconds how long before a new one-time code is to be generated.">
                                                    <b-form-input v-model="enrollment.config.step" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Window" label-align-sm="right" label-cols-sm="3" description="This is how many one-time codes from previous and future cycles are also valid.">
                                                    <b-form-input v-model="enrollment.config.window" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="enrollment.subtype === 'jwt:spki'">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'key')">
                                    <b-img src="/img/factors/jwt/spki.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Private Key</h5>
                                </b-card-header>
                                <b-collapse id="key" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Public Key" label-align-sm="right" label-cols-sm="3" description="This is the public key that belongs to the private key.">
                                                    <b-input-group>
                                                        <b-form-input v-model="enrollment.config.public_key" readonly></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="primary" v-on:click="$emit('save', { subtype: 'jwt:spki', output: enrollment.config.public_key })">Download</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body>
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'security')">
                                    <b-img src="/img/icons/security.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Security</h5>
                                </b-card-header>
                                <b-collapse id="security" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Attempts" label-align-sm="right" label-cols-sm="3" description="This is the current amount of consecutive unsuccessful attempts, which includes pending attempts.">
                                                    <b-form-input v-model="enrollment.attempts" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Max Attempts" label-align-sm="right" label-cols-sm="3" description="This is the maximum amount of consecutive unsuccessful attempts before the factor gets locked.">
                                                    <b-form-input v-model="enrollment.config.max_attempts" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Auto Unlock" label-align-sm="right" label-cols-sm="3" description="This is the time in seconds before the factor if locked gets automatically unlocked.">
                                                    <b-form-input v-model="enrollment.config.auto_unlock" type="number" readonly></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="enrollment">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="enrollment.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="enrollment.created_by" readonly></b-form-input>
                                <b-badge v-if="enrollment.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${enrollment.created_by}`" variant="outline-primary" :disabled="enrollment.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="enrollment.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="enrollment.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="enrollment.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="enrollment.updated_by" readonly></b-form-input>
                                <b-badge v-if="enrollment.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${enrollment.updated_by}`" variant="outline-primary" :disabled="enrollment.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="enrollment.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="enrollment.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="getEnrollment()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="enrollment?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ enrollment.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'secret:id', text: 'Username' },
    { value: 'secret:password', text: 'Password' },
    { value: 'otp', text: 'One-Time Password' },
    { value: 'totp', text: 'Authenticator App' },
    { value: 'jwt:bearer', text: 'Personal Token' },
    { value: 'jwt:spki', text: 'Private Key' },
    { value: 'jwt:jwks', text: 'Hosted Key Set'},
    { value: 'oauth2:quasr', text: 'Quasr' },
    { value: 'oauth2:apple', text: 'Apple' },
    { value: 'oauth2:slack', text: 'Slack' },
    { value: 'oauth2:github', text: 'GitHub' },
    { value: 'oauth2:google', text: 'Google' },
    { value: 'oauth2:discord', text: 'Discord' },
    { value: 'oauth2:linkedin', text: 'LinkedIn' },
    { value: 'oauth2:facebook', text: 'Facebook' },
    { value: 'oauth2:microsoft', text: 'Microsoft' },
    { value: 'oauth2:oidc', text: 'OpenID Connect' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Enrollment',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // ENROLLMENT
            enrollment: undefined,
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.enrollment) {
            this.getEnrollment();
        }
        if (!this.$store.state.factors) {
            this.$emit('load', 'factors', true); // LOAD ALL
        } else if (this.$store.state.factors.nextToken) {
            this.$emit('next', 'factors', true); // LOAD ALL
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * ENROLLMENT
         */
        async getEnrollment() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getEnrollment($id: ID!) {
                                getEnrollment(id: $id) {
                                    id
                                    label
                                    score
                                    status
                                    factor
                                    account
                                    subtype
                                    attempts
                                    config {
                                        case_sensitive
                                        max_attempts
                                        auto_unlock
                                        regex
                                        digits
                                        step
                                        window
                                        public_key
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.enrollment = (await response.json()).data.getEnrollment;
                    // SET DATES
                    this.enrollment.created_at = this.enrollment.created_at ? new Date(this.enrollment.created_at) : undefined;
                    this.enrollment.updated_at = this.enrollment.updated_at ? new Date(this.enrollment.updated_at) : undefined;
                    this.enrollment.expires_at = this.enrollment.expires_at ? new Date(this.enrollment.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.enrollment.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factor.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factor.', 'Factor', 'danger');
            }
            this.loading = undefined;
        }
    }
}
</script>
<!--
TEMPLATE
-->
<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <b-container class="bg-light" style="min-height: 100vh" fluid>

            <!--
            NAVBAR
            -->
            <b-row class="mx-0">
                <b-col class="p-0">
                    <b-navbar class="py-3" type="light" toggleable="lg">
                        <!-- LOGO -->
                        <b-navbar-brand class="mr-2">
                            <b-img v-if="isRoot()" src="/img/logo-blue.png" style="width: 8rem"></b-img>
                            <h3 v-else class="text-primary mb-0"><b>{{ tenant_label }}</b> /</h3>
                        </b-navbar-brand>
                        <!-- NAME -->
                        <b-navbard-nav>
                            <b-nav-text>
                                <h3 class="text-primary mb-0">Admin<b-badge class="ml-2" variant="warning">NEW</b-badge></h3>
                            </b-nav-text>
                        </b-navbard-nav>
                        <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>
                        <b-collapse id="nav-collapse" is-nav>
                            <!-- GENERAL -->
                            <b-navbar-nav class="d-lg-none d-block">
                                <b-nav-item to="/">
                                    <b-img src="/img/menu/dashboard.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Dashboard</span>
                                </b-nav-item>
                                <b-nav-item to="/tenant">
                                    <b-img src="/img/menu/tenant.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Tenant</span>
                                </b-nav-item>
                                <b-nav-item to="/accounts">
                                    <b-img src="/img/menu/accounts.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Accounts</span>
                                    <b-badge v-if="$store.state.accounts?.items.length" class="ml-2" variant="primary">{{ $store.state.accounts.items.length + ($store.state.accounts.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/factors">
                                    <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Factors</span>
                                    <b-badge v-if="$store.state.factors?.items.length" class="ml-2" variant="primary">{{ $store.state.factors.items.length + ($store.state.factors.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/controls">
                                    <b-img src="/img/menu/controls.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Controls</span>
                                    <b-badge v-if="$store.state.controls?.items.length" class="ml-2" variant="primary">{{ $store.state.controls.items.length + ($store.state.controls.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/events">
                                    <b-img src="/img/menu/events.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Events</span>
                                    <b-badge v-if="$store.state.events?.items.length" class="ml-2" variant="primary">{{ $store.state.events.items.length + ($store.state.events.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/extensions">
                                    <b-img src="/img/menu/extensions.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Extensions</span>
                                    <b-badge v-if="$store.state.extensions?.items.length" class="ml-2" variant="primary">{{ $store.state.extensions.items.length + ($store.state.extensions.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                            </b-navbar-nav>
                            <!-- PLATFORM -->
                            <b-navbar-nav class="ml-auto">
                                <b-nav-item href="https://docs.quasr.io" target="_blank">
                                    <b-img src="/img/menu/documentation.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Documentation</span>
                                </b-nav-item>
                                <b-nav-item href="https://discord.com/channels/895325971278856292/895413575491936257" target="_blank">
                                    <b-img src="/img/menu/community.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Community</span>
                                </b-nav-item>
                                <b-nav-item href="https://secure-stats.pingdom.com/1wgwg1ti7t35" target="_blank">
                                    <b-img src="/img/menu/monitoring.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Monitoring</span>
                                </b-nav-item>
                            </b-navbar-nav>
                            <!-- ACCOUNT -->
                            <b-button :href="`https://${tenant_id}.account${domain}`" target="_blank" variant="outline-primary" class="ml-2">Account</b-button>
                            <!-- LOGOUT -->
                            <b-button v-on:click="initiateLogin(true)" variant="outline-danger" class="ml-2">Logout</b-button>
                        </b-collapse>
                    </b-navbar>
                </b-col>
            </b-row>

            <!--
            VIEW
            -->
            <b-row class="pt-4 mx-0">
                <!-- MENU -->
                <b-col lg="2" class="d-none d-lg-block pl-0">
                    <!-- GENERAL -->
                    <b-nav vertical>
                        <b-nav-item to="/">
                            <b-img src="/img/menu/dashboard.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Dashboard</span>
                        </b-nav-item>
                        <b-nav-item to="/tenant">
                            <b-img src="/img/menu/tenant.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Tenant</span>
                        </b-nav-item>
                        <b-nav-item to="/accounts">
                            <b-img src="/img/menu/accounts.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Accounts</span>
                            <b-badge v-if="$store.state.accounts?.items.length" class="ml-2" variant="primary">{{ $store.state.accounts.items.length + ($store.state.accounts.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/factors">
                            <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Factors</span>
                            <b-badge v-if="$store.state.factors?.items.length" class="ml-2" variant="primary">{{ $store.state.factors.items.length + ($store.state.factors.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/controls">
                            <b-img src="/img/menu/controls.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Controls</span>
                            <b-badge v-if="$store.state.controls?.items.length" class="ml-2" variant="primary">{{ $store.state.controls.items.length + ($store.state.controls.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/events">
                            <b-img src="/img/menu/events.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Events</span>
                            <b-badge v-if="$store.state.events?.items.length" class="ml-2" variant="primary">{{ $store.state.events.items.length + ($store.state.events.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/extensions">
                            <b-img src="/img/menu/extensions.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Extensions</span>
                            <b-badge v-if="$store.state.extensions?.items.length" class="ml-2" variant="primary">{{ $store.state.extensions.items.length + ($store.state.extensions.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                    </b-nav>
                </b-col>
                <!-- VIEW -->
                <b-col class="align-items-center" lg="10" xxl="8">
                    <RouterView v-if="hasSession()" v-slot="{ Component }">
                        <component :is="Component" :loading="loading_view" :filter="getFilter" :variant="getVariant" :root="isRoot()" @alert="showAlert" @login="initiateLogin" @load="loadData" @next="loadNext" @show="showModal" @save="saveOutput"/>
                    </RouterView>
                </b-col>
                <!-- SPACE -->
                <b-col xxl="2" class="d-none d-xxl-block">
                </b-col>
            </b-row>

            <!--
            SYSTEM
            -->
            <b-row class="py-4 mx-0 w-100">
                <b-col class="text-muted text-center p-0">
                    <small>
                        <small v-if="isRoot()">{{ getRelease() }} | &copy; Copyright {{ new Date().getFullYear() }} Quasr BV</small>
                        <small v-else>{{ getRelease() }} | Powered by <a :href="getWebsite()" target="_blank">Quasr</a></small>
                    </small>
                </b-col>
            </b-row>

            <!-- CREATE FACTOR -->
            <b-modal id="create-factor" title="Create Factor" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3" :state="!!resource.subtype" invalid-feedback="Please select a subtype.">
                            <b-form-select v-model="resource.subtype" :options="factor_subtypes" :state="!!resource.subtype" v-on:change="setFactor()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="resource.subtype">
                    <b-row>
                        <b-col>
                            <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="resource.label"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Score" label-align-sm="right" label-cols-sm="3" description="This is the security score a user will accumulate by succesfully passing this factor." :state="validField('score') ? null : false" invalid-feedback="Please provide a valid score. The minimum is 0.">
                                <b-form-input v-model="resource.score" type="number" min="0" :state="validField('score') ? null : false"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['otp'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) to which the input must match., e.g. email or phone number." :state="validField('regex')" invalid-feedback="Please provide an input regex.">
                                <b-form-input v-model="resource.config.regex" :state="validField('regex')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="resource.subtype.startsWith('oauth2') && !['oauth2:quasr'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the ID for the client as provided by the Identity Provider (IDP)." :state="validField('client_id')" invalid-feedback="Please provide the client ID.">
                                <template #label>
                                    Client ID<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge><b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge>
                                </template>
                                <b-form-input v-model="resource.config.client_id" :state="validField('client_id')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="resource.subtype.startsWith('oauth2') && !['oauth2:quasr','oauth2:oidc'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the shared secret for the client as provided by the Identity Provider (IDP)." :state="validField('client_secret')" invalid-feedback="Please provide the client secret.">
                                <template #label>
                                    Client Secret<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge>
                                </template>
                                <b-form-input v-model="resource.config.client_secret" :state="validField('client_secret')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['oauth2:oidc'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the OAuth 2.0 Authorization endpoint of the Identity Provider (IDP). Used to initiate an authorization request, i.e. start a login." :state="validField('authorization_endpoint')" invalid-feedback="Please provide the OAuth 2.0 authorization endpoint of the Identity Provider (IDP). Must be an URL.">
                                <template #label>
                                    Authorization Endpoint<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge><b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge>
                                </template>
                                <b-form-input v-model="resource.config.authorization_endpoint" :state="validField('authorization_endpoint')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['secret:id','secret:password'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) to which the input must match.">
                                <b-form-input v-model="resource.config.regex"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="!['totp'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="Unique" label-align-sm="right" label-cols-sm="3" description="This indicates whether each input must be unique. Note that only unique factors can be used as a first factor for login or signup.">
                                <b-form-checkbox v-model="resource.config.unique" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['secret:id','secret:password','otp','oauth2:oidc'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="Case Sensitive" label-align-sm="right" label-cols-sm="3" description="This indicates whether the input is case sensitive.">
                                <b-form-checkbox v-model="resource.config.case_sensitive" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['secret:id','secret:password'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="Threshold" label-align-sm="right" label-cols-sm="3" description="This is the security treshold that the input must meet.">
                                <b-form-select v-model="resource.config.threshold" :options="factor_thresholds"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="['otp'].includes(resource.subtype)">
                        <b-col>
                            <b-form-group label="OTP Regex" label-align-sm="right" label-cols-sm="3" description="This is the regex by which One-Time Passwords (OTPs) are generated.">
                                <b-form-input v-model="resource.config.otp"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-factor')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createFactor()" :disabled="!resource.subtype || !validFactor()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE CONTROL -->
            <b-modal id="create-control" title="Create Control" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3" :state="!!resource.subtype" invalid-feedback="Please select a subtype.">
                            <b-form-select v-model="resource.subtype" :options="control_subtypes" :state="!!resource.subtype" v-on:change="setControl()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="resource.subtype">
                    <b-row>
                        <b-col>
                            <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="resource.label"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Score" label-align-sm="right" label-cols-sm="3" description="This is the minimum security score a user needs to succesfully pass this control." :state="validField('score') ? null : false" invalid-feedback="Please provide a valid score. The minimum is 0.">
                                <b-form-input v-model="resource.score" type="number" min="0" :state="validField('score') ? null : false"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label-align-sm="right" label-cols-sm="3" :description="resource.subtype === 'scope' ? 'This is the name of the OAuth 2.0 scope that will be included in an access token (used for API access).' : 'This can either be an URL to the legal text or the legal text itself. Note that our Login UI only supports URLs.'" :state="validField('value')" invalid-feedback="Please provide a valid value.">
                                <template #label>
                                    Value<b-badge v-if="resource.subtype === 'scope'" class="ml-2" variant="oauth2">OAuth 2.0</b-badge>
                                </template>
                                <b-form-input v-model="resource.value" :state="validField('value')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-control')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createControl()" :disabled="!resource.subtype || !validControl()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE ACCOUNT CONTROL -->
            <b-modal id="create-account-control" title="Create Control" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Type" label-align-sm="right" label-cols-sm="3" description="A permission grants an account access to a restricted scope while a rule configures which controls map to a client (i.e. which legal texts users need to accept and which scopes a client can request). Note that a client would only need a permission when acting on its own behalf instead of a user (i.e. client credentials grant)." :state="!!resource.type" invalid-feedback="Please select a type.">
                            <b-form-select v-model="resource.type" :options="resource.types" :state="!!resource.type" v-on:change="setAccountControl()" :disabled="resource.type && resource.types.length < 2"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="resource.type">
                    <b-row>
                        <b-col>
                            <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="resource.label"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Control" label-align-sm="right" label-cols-sm="3" :state="validField('control')" invalid-feedback="Please select a control. If no controls are shown/available please go to 'Controls' and configure the relevant controls first.">
                                <b-form-select v-model="resource.control" :options="controls()" value-field="id" text-field="label" :state="validField('control')"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="resource.type === 'rule'">
                        <b-col>
                            <b-form-group label="Required" label-align-sm="right" label-cols-sm="3" description="This indicates whether the control must be passed.">
                                <b-form-checkbox v-model="resource.required" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-account-control')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createAccountControl()" :disabled="!resource.type || !validAccountControl()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE ACCOUNT -->
            <b-modal id="create-account" title="Create Account" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3" :state="!!resource.subtype" invalid-feedback="Please select a subtype.">
                            <b-form-select v-model="resource.subtype" :options="account_subtypes" :state="!!resource.subtype" v-on:change="setAccountResource()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="resource.subtype">
                    <b-row>
                        <b-col>
                            <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="resource.label"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div v-if="resource.subtype === 'client'">
                        <b-row>
                            <b-col>
                                <b-form-group label-align-sm="right" label-cols-sm="3" description="These are the allowed grant types." :state="validField('grant_types')" invalid-feedback="Please select a least 1 grant type. Only refresh token is not valid as this is an add-on to the other grants.">
                                    <template #label>
                                        Grant Types<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge><b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge>
                                    </template>
                                    <b-form-checkbox-group v-model="resource.config.grant_types" :options="grant_types" stacked switches :state="validField('grant_types')" v-on:change="resource.config.authentication = {}"></b-form-checkbox-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="resource.config?.grant_types?.some(grant_type => [ 'client_credentials', 'urn:ietf:params:oauth:grant-type:jwt-bearer' ].includes(grant_type))">
                            <b-col>
                                <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the client authentication method." :state="validField('authentication_method')" invalid-feedback="Please select a method.">
                                    <template #label>
                                        Authentication Method<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge>
                                    </template>
                                    <b-form-select v-model="resource.config.authentication.method" :options="client_authentications" :state="validField('authentication_method')" v-on:change="delete resource.config.authentication.factor"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="resource.config?.authentication?.method && !['none', 'session'].includes(resource.config.authentication.method)">
                            <b-col>
                                <b-form-group label="Authentication Factor" label-align-sm="right" label-cols-sm="3" description="This is the client authentication factor." :state="validField('authentication_factor')" invalid-feedback="Please select a factor. If no factors are available please go to 'Factors' and create or enable the relevant factors first.">
                                    <b-form-select v-model="resource.config.authentication.factor" :options="factors()" value-field="id" text-field="label" :state="validField('authentication_factor')"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-account')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createAccount()" :disabled="!resource.subtype || !validAccount()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE EXTENSION -->
            <b-modal id="create-extension" title="Create Extension" size="xl" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" label-cols-xl="2" label-cols-xxl="1">
                            <b-form-input v-model="resource.label"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Code" label-align-sm="right" label-cols-sm="3" label-cols-xl="2" label-cols-xxl="1" description="This is the code run by the extension." :state="validField('code')" invalid-feedback="Please provide valid code.">
                            <b-form-textarea v-model="resource.config.code" rows="10" :state="validField('code')"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Rule" label-align-sm="right" label-cols-sm="3" label-cols-xl="2" label-cols-xxl="1" description="Whether to automatically trigger the extension on certain platform events (asynchronous). Note that for synchronous invocation you must set the extension within the relevant components after successful creation and no rule is required.">
                            <b-form-checkbox v-on:change="resource.config.rule ? delete resource.config.rule : resource.config.rule = {}" switch></b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="resource.config.rule">
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Types" label-align-sm="right" label-cols-sm="3" description="The event types to trigger the extension. Note that not providing any category is equal to all categories.">
                            <b-form-tags :value="resource.config.rule.type" v-on:input="resource.config.rule.type = $event.map(type => type.toUpperCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label-align-sm="right" label-cols-sm="3" description="The event origins to trigger the extension. Note that not providing any category is equal to all categories.">
                            <template #label>
                                Origins<b-badge class="ml-2" variant="warning">NEW</b-badge>
                            </template>
                            <b-form-tags :value="resource.config.rule.origin" v-on:input="resource.config.rule.origin = $event.map(origin => origin.toLowerCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label-align-sm="right" label-cols-sm="3" description="The event accounts to trigger the extension. Note that not providing any category is equal to all categories.">
                            <template #label>
                                Accounts<b-badge class="ml-2" variant="warning">NEW</b-badge>
                            </template>
                            <b-form-tags :value="resource.config.rule.account" v-on:input="resource.config.rule.account = $event.map(account => account.toLowerCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Actions" label-align-sm="right" label-cols-sm="3" description="The event actions to trigger the extension. Note that not providing any category is equal to all categories.">
                            <b-form-tags :value="resource.config.rule.action" v-on:input="resource.config.rule.action = $event.map(action => action.replaceAll(' ','-').toLowerCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Result" label-align-sm="right" label-cols-sm="3" description="The event results to trigger the extension. Note that not providing any category is equal to all categories.">
                            <b-form-tags :value="resource.config.rule.result" v-on:input="resource.config.rule.result = $event.map(result => result.toUpperCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Reasons" label-align-sm="right" label-cols-sm="3" description="The event reasons to trigger the extension. Note that not providing any category is equal to all categories.">
                            <b-form-tags :value="resource.config.rule.reason" v-on:input="resource.config.rule.reason = $event.map(reason => reason.replaceAll(' ','_').toUpperCase())"></b-form-tags>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-extension')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createExtension()" :disabled="!validExtension()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE ACCOUNT -->
            <b-modal id="delete-account" :title="`Delete Account (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete an account. This action can not be undone. All account resources will also be deleted (factors & controls).
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-account')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('account', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE FACTOR -->
            <b-modal id="delete-factor" :title="`Delete Factor (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a factor. This action can not be undone. All factor resources will also be deleted (enrollments). Please make sure that all accounts have sufficient factors and enrollments in order to still gain access.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-factor')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('factor', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE CONTROL -->
            <b-modal id="delete-control" :title="`Delete Control (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a control. This action can not be undone. All control resources will also be deleted (consents, permissions & rules). Please make sure that all accounts have sufficient controls in order to still protect access.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-control')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('control', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE EXTENSION -->
            <b-modal id="delete-extension" :title="`Delete Extension (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete an extension. This action can not be undone.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-extension')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('extension', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE PERMISSION -->
            <b-modal id="delete-permission" :title="`Delete Permission (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a permission. This action can not be undone.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-permission')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('permission', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE RULE -->
            <b-modal id="delete-rule" :title="`Delete Rule (${resource?.label || resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a rule. This action can not be undone. Please make sure that the account has sufficient rules in order to still protect access.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-rule')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('rule', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- SAVE OUTPUT -->
            <b-modal id="save-output" :title="`Save Output (${resource?.label})`" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered hide-header-close>
                <b-row class="p-2">
                    <b-col class="text-center">
                        <b-form-textarea v-model="resource.output" size="sm" max-rows="5" no-resize readonly></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row class="p-2">
                    <b-col class="text-center">
                        <span v-if="resource.subtype === 'invite_token'">We've generated the above invite token for the account. This is the only time you will be able to obtain it so please make sure to save it now. The invite token can be used by the account to complete signup.</span>
                        <span v-else-if="resource.subtype === 'jwt:spki'">We've generated the above key pair for the account. This is the only time you will be able to obtain it in clear so please make sure to save it now. The private key is what the account will need for login.</span>
                        <span v-else>We've generated the above secret for the account. This is the only time you will be able to obtain it in clear so please make sure to save it now.</span>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="success" class="ml-auto" v-on:click="saveOutput()">{{ resource.subtype.startsWith('jwt') ? 'Download' : 'Copy' }}</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

        </b-container>
    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>
/**
 * IMPORTS
 */
import RandExp from 'randexp';
import * as PKCE_CHALLENGE from 'pkce-challenge';
import { jwtVerify, createRemoteJWKSet } from 'jose';
import tinycolor from 'tinycolor2';

/**
 * CONFIGURATION
 */
const ENVIRONMENT = 'prod';
const BASIC_AUTHZ = '';
const UPDATE_DATE = '2024.12.07';
const ROOT_TENANT = 'b62a482d-7365-4ae9-85a5-1453b3b0d5b7';
const ROOT_CLIENT = 'aa6dc5a9-3592-4f96-8928-fc2a12edb60c';
const DOMAIN = ENVIRONMENT === 'prod' ? '.quasr.io' : `-${ENVIRONMENT}.quasr.io`;
const ID_REGEX = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');
const NONCE_REGEX = new RandExp('[a-zA-Z0-9]{43}');
const FACTOR_SUBTYPES = [
    { value: 'secret:id', text: 'Username' },
    { value: 'secret:password', text: 'Password' },
    { value: 'otp', text: 'One-Time Password' },
    { value: 'totp', text: 'Authenticator App' },
    { value: 'jwt:bearer', text: 'Personal Token' },
    { value: 'jwt:spki', text: 'Private Key' },
    { value: 'jwt:jwks', text: 'Hosted Key Set'},
    { value: 'oauth2:quasr', text: 'Quasr' },
    { value: 'oauth2:apple', text: 'Apple' },
    { value: 'oauth2:slack', text: 'Slack' },
    { value: 'oauth2:github', text: 'GitHub' },
    { value: 'oauth2:google', text: 'Google' },
    { value: 'oauth2:discord', text: 'Discord' },
    { value: 'oauth2:linkedin', text: 'LinkedIn' },
    { value: 'oauth2:facebook', text: 'Facebook' },
    { value: 'oauth2:microsoft', text: 'Microsoft' },
    { value: 'oauth2:oidc', text: 'OpenID Connect' }
];
const FACTOR_THRESHOLDS = [
    { value: 0, text: 'None' },
    { value: 1, text: 'Low' },
    { value: 2, text: 'Medium' },
    { value: 3, text: 'High' },
    { value: 4, text: 'Very High' }
];
const ACCOUNT_SUBTYPES = [
    { value: 'user', text: 'User' },
    { value: 'client', text: 'Client' }
];
const CONTROL_SUBTYPES = [
    { value: 'legal', text: 'Legal' },
    { value: 'scope', text: 'Scope' }
];
const GRANT_TYPES = [
    { value: 'authorization_code', text: 'Authorization Code (Browser)' },
    { value: 'urn:ietf:params:oauth:grant-type:jwt-bearer', text: 'JWT Bearer (Native)' },
    { value: 'client_credentials', text: 'Client Credentials' },
    { value: 'refresh_token', text: 'Refresh Token' }
];
const CLIENT_AUTHENTICATIONS = [
    { value: 'client_secret_basic', text: 'Client Secret (Header)' },
    { value: 'client_secret_post', text: 'Client Secret (Body)' },
    { value: 'private_key_jwt', text: 'Private Key (JWT)' },
    { value: 'session', text: 'Session' }
];

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'App',

    /**
     * DATA
     */
    data() {
        return {
            // TENANT (ID)
            tenant_id: ROOT_TENANT,
            // TENANT (LABEL)
            tenant_label: undefined,
            // CLIENT
            client_id: ROOT_CLIENT,
            // LOADING
            loading: undefined,
            // LOADING (VIEW)
            loading_view: undefined,
            // RESOURCE
            resource: undefined,
            // DOMAIN
            domain: DOMAIN,
            // FACTOR SUBTYPES
            factor_subtypes: FACTOR_SUBTYPES,
            // FACTOR THRESHOLD
            factor_thresholds: FACTOR_THRESHOLDS,
            // ACCOUNT SUBTYPES
            account_subtypes: ACCOUNT_SUBTYPES,
            // CONTROL SUBTYPES
            control_subtypes: CONTROL_SUBTYPES,
            // GRANT TYPES
            grant_types: GRANT_TYPES,
            // CLIENT AUTHENTICATIONS
            client_authentications: CLIENT_AUTHENTICATIONS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        this.loading = 'Initializing';
        const { code, id_token } = await this.initialize();
        // LOGIN
        if (code && id_token) {
            await Promise.all([this.processToken(id_token), this.processCode(code)]);
            this.clearState();
            this.$router.push('/'); // REMOVE QUERY
        }
        // SESSION
        if (this.hasSession() && !this.checkExpiration()) {
            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
            this.clearSession();
            return this.initiateLogin(); // KEEP LOADING
        } else if (!this.hasSession()) {
            return this.initiateLogin(); // KEEP LOADING
        }
        this.$store.commit('session', this.getSession());
        this.$store.commit('account_id', this.getAccount());
        this.loading = undefined;
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            // DOMAIN
            this.$store.commit('domain', DOMAIN);
            // TENANT ID
            const params = new URLSearchParams(window.location.search);
            const tenant_id = location.host.split('.')[0];
            if (ID_REGEX.test(tenant_id)) {
                this.tenant_id = tenant_id;
            } else if (params.has('tenant_id')) {
                this.tenant_id = params.get('tenant_id');
            }
            this.$store.commit('tenant_id', this.tenant_id);
            await this.client();
            // LOGIN
            return {
                code: params.get('code'),
                id_token: params.get('id_token')
            };
        },

        async client() {
            try {

                // EXCHANGE CODE
                const response = await fetch(`https://api${DOMAIN}/tenants/${this.tenant_id}`, {
                    method: 'GET',
                    headers: this.hasAuthorization() ? {
                        Authorization: this.getAuthorization()
                    } : {}
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenant = await response.json();
                    this.tenant_label = tenant.label;
                    this.client_id = tenant.admin_client;
                    this.$store.commit('client_id', this.client_id);
                    // CUSTOMIZATION
                    var css = document.createElement('style');
                    var color = tinycolor(tenant.color);
                    var color_hover = color.darken().toHexString();
                    var color_disabled = color.lighten(40).toHexString();
                    css.textContent = `
                        a:not(.btn) {
                            color: ${tenant.color};
                        }
                        .bg-primary {
                            background-color: ${tenant.color} !important;
                        }
                        .text-primary {
                            color: ${tenant.color} !important;
                        }
                        .badge-primary {
                            background-color: ${tenant.color} !important;
                        }
                        .badge-secondary {
                            background-color: ${tenant.color} !important;
                        }
                        .btn-primary {
                            background-color: ${tenant.color} !important;
                            border-color: ${tenant.color} !important;
                        }
                        .btn-primary:not(:disabled):hover {
                            background-color: ${color_hover} !important;
                            border-color: ${color_hover} !important;
                        }
                        .btn-outline-primary:not(:hover) {
                            color: ${tenant.color} !important;
                            border-color: ${tenant.color} !important;
                        }
                        .btn-outline-primary:disabled:hover {
                            color: ${tenant.color} !important;
                            border-color: ${tenant.color} !important;
                        }
                        .btn-outline-primary:not(:disabled):hover {
                            background-color: ${tenant.color} !important;
                            border-color: ${tenant.color} !important;
                        }
                        .custom-control-input:checked~.custom-control-label:before {
                            border-color: ${tenant.color} !important;
                            background-color: ${tenant.color} !important;
                        }
                        .custom-control-input:disabled:checked~.custom-control-label:before {
                            background-color: ${color_disabled} !important;
                        }
                        .active {
                            background-color: ${tenant.color} !important;
                        }
                        .active:hover {
                            background-color: ${color_hover} !important;
                        }
                    `;
                    document.head.appendChild(css);
                } else {
                    this.showAlert('Failed to obtain tenant details.', 'Initialization', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to obtain tenant details.', 'Initialization', 'danger');
            }
        },

        /**
         * AUTHORIZATION
         */
        getAuthorization() {
            if (BASIC_AUTHZ) {
                return `Basic ${BASIC_AUTHZ}`;
            } else {
                return undefined;
            }
        },

        hasAuthorization() {
            return !!BASIC_AUTHZ;
        },

        /**
         * LOGIN
         */
        async initiateLogin(logout) {
            this.loading = `Logging ${logout ? 'Out' : 'In'}`;
            
            // CLEAR SESSION
            this.clearSession();

            // PREPARE STATE
            const nonce = NONCE_REGEX.gen();
            const code = await PKCE_CHALLENGE.default();
            await this.setState(nonce, code.code_verifier);

            // PREPARE REDIRECT
            const redirect = new URL(`https:/${this.tenant_id}.api${DOMAIN}/oauth2/authorize`);
            redirect.searchParams.append('client_id', this.client_id);
            redirect.searchParams.append('response_type', `code id_token`);
            redirect.searchParams.append('code_challenge', code.code_challenge);
            redirect.searchParams.append('code_challenge_method', 'S256');
            redirect.searchParams.append('nonce', nonce); // ID TOKEN
            redirect.searchParams.append('redirect_uri', document.location.origin); // OPTIONAL
            redirect.searchParams.append('scope', `openid https://api${DOMAIN}/scopes/admin`);
            redirect.searchParams.append('mode', 'login');
            if (logout) redirect.searchParams.append('prompt', 'login');

            // PERFORM REDIRECT
            document.location.href = redirect.href;
            this.loading = 'Redirecting to Quasr Login';
        },

        async processToken(id_token) {
            try {
                
                // VERIFY TOKEN
                const payload = (await jwtVerify(id_token, createRemoteJWKSet(new URL(`https://${this.tenant_id}.api${DOMAIN}/.well-known/jwks.json`)), {
                    issuer: `https://${this.tenant_id}.api${DOMAIN}`,
                    audience: this.client_id
                })).payload;

                // VERIFY NONCE
                if (payload.nonce === this.getNonce()) {
                    this.setAccount(payload.sub);
                } else {
                    this.showAlert('Failed to accept identity token.', 'Authentication', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to accept identity token.', 'Authentication', 'danger');
            }
        },

        async processCode(code) {
            try {

                // EXCHANGE CODE
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/oauth2/token`, {
                    method: 'POST',
                    body: JSON.stringify({
                        client_id: this.client_id,
                        grant_type: 'authorization_code',
                        code: code,
                        code_verifier: this.getCodeVerifier()
                    }),
                    headers: this.hasAuthorization() ? {
                        'Content-Type': 'application/json', 
                        Authorization: this.getAuthorization()
                    } : {
                        'Content-Type': 'application/json'
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tokens = await response.json();
                    this.setSession(tokens.access_token, tokens.expires_at);
                } else {
                    this.showAlert('Failed to obtain access token.', 'Authorization', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to obtain access token.', 'Authorization', 'danger');
            }
        },

        /**
         * STATE
         */
        getNonce() {
            return localStorage.getItem(`${this.tenant_id}#NONCE`);
        },

        getCodeVerifier() {
            return localStorage.getItem(`${this.tenant_id}#CODE_VERIFIER`);
        },

        hasState() {
            return this.getNonce() !== null;
        },

        async setState(nonce, code_verifier) {
            localStorage.setItem(`${this.tenant_id}#NONCE`, nonce);
            localStorage.setItem(`${this.tenant_id}#CODE_VERIFIER`, code_verifier);
        },

        async clearState() {
            localStorage.removeItem(`${this.tenant_id}#NONCE`);
            localStorage.removeItem(`${this.tenant_id}#CODE_VERIFIER`);
        },

        /**
         * SESSION
         */
        getSession() {
            return localStorage.getItem(`${this.tenant_id}#SESSION`);
        },

        getAccount() {
            return localStorage.getItem(`${this.tenant_id}#ACCOUNT`);
        },

        hasSession() {
            return this.getSession() !== null;
        },

        setSession(session, expiration) {
            localStorage.setItem(`${this.tenant_id}#SESSION`, session);
            localStorage.setItem(`${this.tenant_id}#EXPIRATION`, expiration);
        },

        setAccount(account) {
            localStorage.setItem(`${this.tenant_id}#ACCOUNT`, account);
        },

        clearSession() {
            localStorage.removeItem(`${this.tenant_id}#SESSION`);
            localStorage.removeItem(`${this.tenant_id}#EXPIRATION`);
            localStorage.removeItem(`${this.tenant_id}#ACCOUNT`);
        },

        getExpiration() {
            return parseInt(localStorage.getItem(`${this.tenant_id}#EXPIRATION`)) * 1000;
        },

        checkExpiration() {
            return (new Date().getTime()) < this.getExpiration();
        },

        /**
         * ALERT
         */
        async showAlert(message, title, variant, delay) {
            this.$bvToast.toast(message, {
                title: title,
                // toaster: 'b-toaster-top-center',
                variant: variant,
                autoHideDelay: delay,
                noAutoHide: !delay
            });
        },

        /**
         * STATUS
         */
        getVariant(status) {
            switch (status) {
                case 'LOCKED':
                case 'FAILED':
                    return 'danger';
                case 'PENDING':
                    return 'warning';
                case 'ENABLED':
                case 'SUCCESS':
                    return 'success';
                default: // DISABLED
                    return 'secondary';
            }
        },

        /**
         * FIlTER
         * 
         * See: https://codepen.io/sosuke/pen/Pjoqqp
         */
        getFilter(variant) {
            switch (variant) {
                case 'primary':
                    return 'invert(48%) sepia(15%) saturate(3187%) hue-rotate(183deg) brightness(89%) contrast(89%)';
                case 'secondary':
                    return 'invert(45%) sepia(6%) saturate(672%) hue-rotate(167deg) brightness(98%) contrast(88%)';
                case 'success':
                    return 'invert(52%) sepia(23%) saturate(1324%) hue-rotate(81deg) brightness(96%) contrast(94%)';
                case 'info':
                    return 'invert(69%) sepia(11%) saturate(4319%) hue-rotate(140deg) brightness(77%) contrast(83%)';
                case 'warning':
                    return 'invert(78%) sepia(84%) saturate(2275%) hue-rotate(355deg) brightness(101%) contrast(102%)';
                case 'danger':
                    return 'invert(33%) sepia(100%) saturate(897%) hue-rotate(320deg) brightness(85%) contrast(107%)';
                case 'light':
                    return 'invert(98%) sepia(3%) saturate(517%) hue-rotate(97deg) brightness(106%) contrast(94%)';
                default: // DARK
                    return 'invert(19%) sepia(8%) saturate(952%) hue-rotate(169deg) brightness(91%) contrast(85%)';
            }
        },

        /**
         * SYSTEM
         */
        getWebsite() {
            return `https://www${DOMAIN}`;
        },

        getRelease() {
            return this.isProduction() ? UPDATE_DATE : `${UPDATE_DATE}-${ENVIRONMENT}`;
        },

        isRoot() {
            return this.tenant_id === ROOT_TENANT;
        },

        isProduction() {
            return ENVIRONMENT === 'prod';
        },

        /**
         * MODALS
         */
        showModal(name, resource) {
            if (resource) this.resource = resource;
            this.$bvModal.show(name);
        },

        /**
         * DATA
         */
        async loadData(resource, all) {
            this.loading_view = 'Loading';
            try {

                // GET RESPONSE
                const response = await this.getResponse(resource);

                // VERIFY RESPONSE
                if (response.ok) {
                    const resource_json = await this.parseResponse(resource, response);
                    // ADD REFRESH DATE
                    resource_json.refreshed_at = new Date();
                    this.$store.commit(resource, resource_json);
                    // MORE AVAILABLE
                    if (resource_json.nextToken) {
                        if (all) {
                            return this.loadNext(resource, resource_json.nextToken, all); // KEEP LOADING
                        } else {
                            this.showAlert(`More ${resource} are available but were not loaded due to preserve bandwidth. You can load them by clicking 'Load More' below.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = undefined;
        },

        async loadNext(resource, nextToken, all) {
            this.loading_view = 'Loading';
            try {

                // GET RESPONSE
                const response = await this.getResponse(resource, nextToken);

                // VERIFY RESPONSE
                if (response.ok) {
                    const resource_json = await this.parseResponse(resource, response);
                    // ADD NEW ITEMS
                    for (const item of resource_json.items) {
                        this.$store.commit(`push_${resource.slice(0, -1)}`, item);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit(`set_${resource}_token`, resource_json.nextToken);
                    // MORE AVAILABLE
                    if (resource_json.nextToken) {
                        if (all) {
                            return this.loadNext(resource, resource_json.nextToken, all); // KEEP LOADING
                        } else {
                            this.showAlert(`More ${resource} are available but were not loaded due to preserve bandwidth. You can load them by clicking 'Load More' below.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = false;
        },

        async getResponse(resource, nextToken) {
            switch (resource) {

                // GRAPHQL
                case 'tenant':
                    return await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query getTenant($id: ID!) {
                                    getTenant(id: $id) {
                                        id
                                        label
                                        status
                                        account
                                        subscription
                                        metrics {
                                            maa
                                            mac
                                            updated_at
                                        }
                                        config {
                                            tokens {
                                                login {
                                                    exp
                                                    use
                                                }
                                                signup {
                                                    exp
                                                    use
                                                }
                                                consent {
                                                    exp
                                                    use
                                                }
                                            }
                                            accounts {
                                                pending {
                                                    exp
                                                }
                                                enabled {
                                                    exp
                                                }
                                            }
                                            extensions {
                                                pending {
                                                    exp
                                                }
                                                enabled {
                                                    exp
                                                    max
                                                }
                                            }
                                            interfaces {
                                                color
                                                account {
                                                    client
                                                }
                                                admin {
                                                    client
                                                }
                                            }
                                            hlp {
                                                background_color
                                                background_text_color
                                                background_link_color
                                                widget_color
                                                widget_text_color
                                                widget_link_color
                                                logo
                                            }
                                        }
                                        created_at
                                        created_by
                                        updated_at
                                        updated_by
                                        expires_at
                                    }
                                }
                            `,
                            variables: `{
                                "id": "${this.tenant_id}"
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.getSession()}`
                        }
                    });

                case 'accounts':
                    return await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listAccounts($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listAccounts(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            status
                                            subtype
                                            created_at
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 100${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.getSession()}`
                        }
                    });
                
                case 'factors':
                    return await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listFactors($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listFactors(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            score
                                            status
                                            subtype
                                            created_at
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 100${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.getSession()}`
                        }
                    });
                
                case 'controls':
                    return await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listControls($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listControls(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            score
                                            status
                                            subtype
                                            created_at
                                            config {
                                                permission_required
                                            }
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 100${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.getSession()}`
                        }
                    });
                    
                case 'extensions':
                    return await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listExtensions($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listExtensions(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            status
                                            created_at
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 100${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.getSession()}`
                        }
                    });

            }
        },

        async parseResponse(resource, response) {
            switch (resource) {

                // GRAPHQL (GET)
                case 'tenant':
                    return (await response.json()).data[`get${resource.charAt(0).toUpperCase() + resource.slice(1)}`];

                // GRAPHQL (LIST)
                case 'accounts':
                case 'factors':
                case 'controls':
                case 'extensions':
                    return (await response.json()).data[`list${resource.charAt(0).toUpperCase() + resource.slice(1)}`];

            }
        },

        async deleteData(resource, id) {
            this.loading_view = 'Deleting';
            this.$bvModal.hide(`delete-${resource}`);
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation delete${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Delete${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                delete${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.showAlert(`Your ${resource} has been deleted.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'success', 5000);
                    this.loadData(`${resource}s`);
                    this.$router.push(`/${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to delete ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to delete ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = undefined;
        },

        /**
         * FACTOR
         */
        async createFactor() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-factor');
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation createFactor($input: CreateFactorInput!) {
                                createFactor(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "label": "${this.resource.label}",
                                "subtype": "${this.resource.subtype}",
                                "score": ${this.resource.score},
                                "config": {
                                    "regex": "${this.resource.config.regex}",
                                    "unique": ${this.resource.config.unique ?? null /* NULLABLE */},
                                    "case_sensitive": ${this.resource.config.case_sensitive ?? null /* NULLABLE */},
                                    "threshold": ${this.resource.config.threshold ?? null /* NULLABLE */},
                                    "otp": "${this.resource.config.otp}",
                                    "client_id": "${this.resource.config.client_id}",
                                    "client_secret": "${this.resource.config.client_secret}",
                                    "authorization_endpoint": "${this.resource.config.authorization_endpoint}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const factor = (await response.json()).data.createFactor;
                    this.showAlert('Your factor has been created.', 'Factor', 'success', 5000);
                    this.loadData('factors');
                    this.$router.push(`/factors/${factor.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create factor.', 'Factor', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create factor', 'Factor', 'danger');
            }
            this.loading_view = undefined;
        },

        setFactor() {
            switch (this.resource.subtype) {
                case 'secret:id':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Username',
                        score: 0,
                        config: {
                            unique: true,
                            case_sensitive: false,
                            regex: '^.{1,100}$',
                            threshold: 0
                        }
                    };
                    return;
                case 'secret:password':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Password',
                        score: 0,
                        config: {
                            unique: false,
                            case_sensitive: true,
                            regex: '^.{15,100}$',
                            threshold: 2
                        }
                    };
                    return;
                case 'jwt:spki':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Private Key',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'jwt:jwks':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Hosted Key Set',
                        score: 0,
                        config: {
                            unique: false
                        }
                    };
                    return;
                case 'jwt:bearer':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Personal Token',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'otp':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'One-Time Password',
                        score: 0,
                        config: {
                            unique: true,
                            case_sensitive: false,
                            otp: '[A-Z0-9]{6}'
                        }
                    };
                    return;
                case 'totp':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Authenticator App',
                        score: 0,
                        config: {}
                    };
                    return;
                case 'oauth2:facebook':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Facebook',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:google':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Google',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:apple':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Apple',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:linkedin':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'LinkedIn',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:github':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'GitHub',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:slack':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Slack',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:microsoft':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Microsoft',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:discord':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Discord',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:quasr':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Quasr',
                        score: 0,
                        config: {
                            unique: true
                        }
                    };
                    return;
                case 'oauth2:oidc':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'OpenID Connect',
                        score: 0,
                        config: {
                            unique: true,
                            case_sensitive: true
                        }
                    };
                    return;
                default:
                    return;
            }
        },

        /**
         * CONTROL
         */
        async createControl() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-control');
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation createControl($input: CreateControlInput!) {
                                createControl(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "label": "${this.resource.label}",
                                "subtype": "${this.resource.subtype}",
                                "score": ${this.resource.score},
                                "value": "${this.resource.value}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const control = (await response.json()).data.createControl;
                    this.showAlert('Your control has been created.', 'Control', 'success', 5000);
                    this.loadData('controls');
                    this.$router.push(`/controls/${control.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create control.', 'Control', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create control', 'Control', 'danger');
            }
            this.loading_view = undefined;
        },

        setControl() {
            switch (this.resource.subtype) {
                case 'legal':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Legal',
                        score: 0
                    };
                    return;
                case 'scope':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Scope',
                        score: 0
                    };
                    return;
                default:
                    return;
            }
        },

        /**
         * ACCOUNT CONTROL
         */
        async createAccountControl() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-account-control');
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation create${this.resource.type.charAt(0).toUpperCase() + this.resource.type.slice(1)}($input: Create${this.resource.type.charAt(0).toUpperCase() + this.resource.type.slice(1)}Input!) {
                                create${this.resource.type.charAt(0).toUpperCase() + this.resource.type.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "account": "${this.resource.account}",
                                "label": "${this.resource.label}",
                                "control": "${this.resource.control}"${this.resource.type === 'rule' ? `,
                                "required": ${this.resource.required}
                                ` : ''}
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const control = (await response.json()).data[`create${this.resource.type.charAt(0).toUpperCase() + this.resource.type.slice(1)}`];
                    this.showAlert('Your control has been created.', 'Control', 'success', 5000);
                    this.$router.push(`/${this.resource.type}s/${control.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create control.', 'Control', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create control', 'Control', 'danger');
            }
            this.loading_view = undefined;
        },

        setAccountControl() {
            switch (this.resource.type) {
                case 'permission':
                    this.resource = {
                        type: this.resource.type,
                        types: this.resource.types,
                        account: this.resource.account,
                        label: 'Permission'
                    };
                    break;
                case 'rule':
                    this.resource = {
                        type: this.resource.type,
                        types: this.resource.types,
                        account: this.resource.account,
                        label: 'Rule',
                        required: true
                    };
                    break;
                default:
                    break;
            }
            if (!this.$store.state.controls) {
                this.loadData('controls', true); // LOAD ALL
            } else if (this.$store.state.controls.nextToken) {
                this.loadNext('controls', true); // LOAD ALL
            }
        },

        /**
         * ACCOUNT
         */
        async createAccount() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-account');
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation createAccount($input: CreateAccountInput!) {
                                createAccount(input: $input) {
                                    id
                                    invite_token
                                    config {
                                        authentication {
                                            output
                                        }
                                    }
                                }
                            }
                        `,
                        variables: `{
                            "input": {${this.resource.label ? `
                                "label": "${this.resource.label}",` : ''}
                                "subtype": "${this.resource.subtype}"${this.resource.config ? `,
                                "config": {
                                    "grant_types": ${JSON.stringify(this.resource.config.grant_types) /* ARRAY */}${this.resource.config.authentication?.method ? `,
                                    "authentication": {
                                        "method": "${this.resource.config.authentication.method}"${this.resource.config.authentication.factor ? `,
                                        "factor": "${this.resource.config.authentication.factor}"
                                        ` : ''}
                                    }
                                    ` : ''}
                                }
                                ` : ''}
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const account = (await response.json()).data.createAccount;
                    this.showAlert('Your account has been created.', 'Account', 'success', 5000);
                    if (account.invite_token) {
                        this.resource = {
                            label: 'Invite Token',
                            subtype: 'invite_token',
                            output: account.invite_token
                        };
                        this.showModal('save-output');
                    }
                    if (account.config?.authentication?.output) {
                        this.resource = {
                            label: this.resource.config.authentication.method === 'private_key_jwt' ? 'Private Key' : 'Secret',
                            subtype: this.resource.config.authentication.method === 'private_key_jwt' ? 'jwt:spki' : 'secret:password',
                            output: account.config.authentication.output
                        };
                        this.showModal('save-output');
                    }
                    this.loadData('accounts');
                    this.$router.push(`/accounts/${account.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create account.', 'Account', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create account', 'Account', 'danger');
            }
            this.loading_view = undefined;
        },

        setAccountResource() {
            switch (this.resource.subtype) {
                case 'user':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Invited User'
                    };
                    return;
                case 'client':
                    this.resource = {
                        subtype: this.resource.subtype,
                        label: 'Application',
                        config: {
                            authentication: {}
                        }
                    };
                    if (!this.$store.state.factors) {
                        this.loadData('factors', true); // LOAD ALL
                    } else if (this.$store.state.factors.nextToken) {
                        this.loadNext('factors', true); // LOAD ALL
                    }
                    return;
                default:
                    return;
            }
        },

        /**
         * EXTENSION
         */
        async createExtension() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-extension');
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation createExtension($input: CreateExtensionInput!) {
                                createExtension(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "label": "${this.resource.label}",
                                "config": {
                                    "code": "${btoa(this.resource.config.code)}"${this.resource.config.rule ? `,
                                    "rule": {
                                        "type": ${JSON.stringify(this.resource.config.rule.type || null /* NULLABLE */)},
                                        "origin": ${JSON.stringify(this.resource.config.rule.origin || null /* NULLABLE */)},
                                        "account": ${JSON.stringify(this.resource.config.rule.account || null /* NULLABLE */)},
                                        "action": ${JSON.stringify(this.resource.config.rule.action || null /* NULLABLE */)},
                                        "result": ${JSON.stringify(this.resource.config.rule.result || null /* NULLABLE */)},
                                        "reason": ${JSON.stringify(this.resource.config.rule.reason || null /* NULLABLE */)}
                                    }
                                    ` : ''}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.getSession()}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const extension = (await response.json()).data.createExtension;
                    this.showAlert('Your extension has been created.', 'Extension', 'success', 5000);
                    this.loadData('extensions');
                    this.$router.push(`/extensions/${extension.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create extension.', 'Extension', 'danger');
                }

            } catch (error) {
                console.log(error);
                this.showAlert('Failed to create extension', 'Extension', 'danger');
            }
            this.loading_view = undefined;
        },

        /**
         * OUTPUT
         */
        async saveOutput(resource) {
            this.loading_view = 'Saving';
            this.$bvModal.hide('save-output');
            if (resource) this.resource = resource;
            // Save output
            if (this.resource.subtype.startsWith('jwt')) {
                // Prepare files
                const files = [];
                if (this.resource.subtype === 'jwt:spki') {
                    const outputs = this.resource.output.split(';');
                    if (outputs.length > 1) {
                        files.push(new File([outputs[0]], 'private_key.pem', { type: 'application/x-pem-file' }));
                        files.push(new File([outputs[1]], 'public_key.pem', { type: 'application/x-pem-file' }));
                    } else {
                        files.push(new File([outputs[0]], 'public_key.pem', { type: 'application/x-pem-file' }));
                    }
                } else {
                    files.push(new File([this.resource.output], 'personal_token.jwt', { type: 'application/jwt' }))
                }
                // Download files
                for (const file of files) {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(file);
                    // Trigger download
                    link.href = url;
                    link.download = file.name;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    // Cleanup download
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    this.showAlert(`P${file.name.split('.')[0].replace('_',' ').slice(1)} downloaded to file.`, 'Factor', 'success', 5000);
                }
            } else {
                // Copy to clipboard
                await navigator.clipboard.writeText(this.resource.output);
                this.showAlert(`${this.resource.label} copied to clipboard.`, 'Factor', 'success', 5000);
            }
            this.loading_view = undefined;
        },

        /**
         * VALIDATION
         */
        validFactor() {
            if (this.resource.score !== undefined && !this.validField('score')) return false;
            if (this.resource.subtype === 'otp') {
                if (!this.validField('regex')) return false;
            } else if (this.resource.subtype.startsWith('oauth2')) {
                if (!['oauth2:quasr'].includes(this.resource.subtype) && !this.validField('client_id')) return false;
                if (!['oauth2:quasr','oauth2:oidc'].includes(this.resource.subtype) && !this.validField('client_secret')) return false;
                if (['oauth2:oidc'].includes(this.resource.subtype) && !this.validField('authorization_endpoint')) return false;
            }
            return true;
        },

        validControl() {
            if (this.resource.score !== undefined && !this.validField('score')) return false;
            if (!this.validField('value')) return false;
            return true;
        },

        validAccountControl() {
            if (!this.validField('control')) return false;
            return true;
        },

        validAccount() {
            if (this.resource.subtype === 'client') {
                if (!this.validField('grant_types')) return false;
                if (this.resource.config?.grant_types?.some(grant_type => [ 'client_credentials', 'urn:ietf:params:oauth:grant-type:jwt-bearer' ].includes(grant_type))) {
                    if (!this.validField('authentication_method')) return false;
                    if (this.resource.config.authentication.method !== 'session') {
                        if (!this.validField('authentication_factor')) return false;
                    }
                }
            }
            return true;
        },

        validExtension() {
            if (!this.validField('code')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'score':
                    return this.resource.score >= 0;
                case 'value':
                    return !!this.resource.value;
                case 'regex:':
                    return !!this.resource.config.regex;
                case 'client_id':
                    return !!this.resource.config.client_id;
                case 'client_secret':
                    return !!this.resource.config.client_secret;
                case 'authorization_endpoint':
                    return !!this.resource.config.authorization_endpoint && this.isURL(this.resource.config.authorization_endpoint);
                case 'grant_types':
                    return !!this.resource.config?.grant_types && this.resource.config.grant_types.length > 0 && !(this.resource.config.grant_types.length === 1 && this.resource.config.grant_types[0] === 'refresh_token');
                case 'authentication_method':
                    return !!this.resource.config.authentication.method;
                case 'authentication_factor':
                    return !!this.resource.config.authentication.factor;
                case 'code':
                    return !!this.resource.config.code;
                case 'control':
                    return !!this.resource.control;
                default:
                    return false;
            }
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        },

        /**
         * UTILITIES
         */
        factors() {

            // Initialize
            var factors = this.$store.state.factors?.items || [];

            // Filter based on method
            switch (this.resource.config.authentication.method) {
                // PRIVATE KEY JWT
                case 'private_key_jwt':
                    factors = factors.filter(factor => ['jwt:spki','jwt:jwks'].includes(factor.subtype));
                    break;
                // CLIENT SECRET
                default:
                    factors = factors.filter(factor => ['secret:password'].includes(factor.subtype));
                    break;
            }

            // Clone and disable based on status
            return factors.map(factor => ({
                id: factor.id,
                label: factor.label,
                disabled: (factor.status !== 'ENABLED')
            }));

        },

        controls() {

            // Clone and disable based on configuration
            return this.$store.state.controls?.items.map(control => ({
                id: control.id,
                label: control.label,
                disabled: (this.resource.type === 'permission' && !control.config.permission_required)
            })) || [];

        }
    }
}
</script>

<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/events.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Events</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
            </b-card-header>
            <b-card-body v-if="$store.state.events">
                <b-table id="table" outlined hover :items="$store.state.events.items" :fields="fields" primary-key="id" sort-by="time" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" class="mb-0" show-empty empty-text="You have no events yet. It can take up to 15 minutes for events to show up." empty-filtered-text="No events match your search. If more events are available you can load them by clicking 'Load More' below.">
                    <template #cell(origin)="data">
                        <b-link v-if="data.item.type === 'API' && isURL(data.value)" :href="data.value" target="_blank">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('tenant') && !data.item.action.startsWith('delete')" to="/tenant">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('account') && !data.item.action.startsWith('delete')" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('factor') && !data.item.action.startsWith('delete')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('control') && !data.item.action.startsWith('delete')" :to="`/controls/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('extension') && !data.item.action.startsWith('delete')" :to="`/extensions/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('enrollment') && !data.item.action.startsWith('delete')" :to="`/enrollments/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('consent') && !data.item.action.startsWith('delete')" :to="`/consents/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('permission') && !data.item.action.startsWith('delete')" :to="`/permissions/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('rule') && !data.item.action.startsWith('delete')" :to="`/rules/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'AUTHENTICATION'" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'AUTHORIZATION'" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATA'" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('otp')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('account')" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('tenant')" to="/tenant">{{ data.value }}</b-link>
                        <span v-else>{{ data.value }}</span>
                    </template>
                    <template #cell(account_id)="data">
                        <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                    </template>
                    <template #cell(result)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ results[data.value] }}</b-button>
                    </template>
                    <template #cell(detail)="data">
                        <b-button variant="outline-primary" size="sm" v-on:click="data.toggleDetails" :disabled="!data.value || data.value === '{}'">Detail</b-button>
                    </template>
                    <template #row-details="data">
                        <b-card body-bg-variant="light">
                            <b-row>
                                <b-col>
                                    {{ data.item.detail }}
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listEvents()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.events" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.events.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.events?.nextToken" variant="outline-primary" v-on:click="nextEvents()" class="ml-2" :disabled="loading_more">Load More<b-badge v-if="root" class="ml-2" variant="warning">NEW</b-badge></b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.events?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.events.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'origin',
        label: 'Origin',
        sortable: true
    },
    {
        key: 'account_id',
        label: 'Account',
        sortable: true
    },
    {
        key: 'action',
        label: 'Action',
        sortable: true
    },
    {
        key: 'result',
        label: 'Result',
        sortable: true
    },
    {
        key: 'reason',
        label: 'Reason',
        sortable: true
    },
    {
        key: 'detail',
        label: 'Detail',
        sortable: false
    }
];
const RESULTS = {
    'PENDING': 'Pending',
    'SUCCESS': 'Success',
    'FAILED': 'Failed'
};
const TYPES = {
    'API': 'API',
    'DATA': 'Data',
    'DATABASE': 'Database',
    'COMMUNICATION': 'Communication',
    'AUTHENTICATION': 'Authentication',
    'AUTHORIZATION': 'Authorization'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Events',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // RESULTS
            results: RESULTS,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.events) {
            this.listEvents();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * EVENTS
         */
        async listEvents() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($limit: Int) {
                                listEvents(limit: $limit) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 100
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD REFRESH DATE
                    events.refreshed_at = new Date();
                    this.$store.commit('events', events);
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Events', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
            }
            this.loading = undefined;
        },

        async nextEvents() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($limit: Int, $nextToken: String) {
                                listEvents(limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 100,
                            "nextToken": "${this.$store.state.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD NEW EVENTS
                    for (const event of events.items) {
                        this.$store.commit('push_event', event);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_events_token', events.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Events', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
            }
            this.loading_more = false;
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        }
    }
}
</script>
<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/icons/account.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Account</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :account="account" :enrollments="enrollments" :consents="consents" :permissions="permissions" :rules="rules" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" :loading_more="loading_more"/>
            </RouterView>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'AccountCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // ENROLLMENTS
            enrollments: undefined,
            // CONSENTS
            consents: undefined,
            // PERMISSIONS
            permissions: undefined,
            // RULES
            rules: undefined,
            // LOADING
            loading: undefined,
            // ACCOUNT
            account: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
     async created() {
        if (!this.account) {
            this.getAccount();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'account') {
                this.getAccount();
            } else if (args[0] === 'enrollments') {
                this.loadEnrollments(args[1]);
            } else if (args[0] === 'consents') {
                this.listConsents();
            } else if (args[0] === 'permissions') {
                this.listPermissions();
            } else if (args[0] === 'rules') {
                this.listRules();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'enrollments') {
                this.nextEnrollments(args[1]);
            } else if (args[0] === 'consents') {
                this.nextConsents();
            } else if (args[0] === 'permissions') {
                this.nextPermissions();
            } else if (args[0] === 'rules') {
                this.nextRules();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            this.$emit('show', ...args);
        },

        /**
         * ACCOUNT
         */
        async getAccount() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getAccount($id: ID!) {
                                getAccount(id: $id) {
                                    id
                                    label
                                    subtype
                                    status
                                    config {
                                        external
                                        scopes
                                        grant_types
                                        response_types
                                        redirect_uris
                                        login_uri
                                        code_challenge_methods
                                        signed_request
                                        jwks_uri
                                        authentication {
                                            method
                                            factor
                                        }
                                        tokens {
                                            access {
                                                exp
                                                use
                                                aud
                                                ext
                                                jwk
                                            }
                                            code {
                                                exp
                                                use
                                            }
                                            identity {
                                                exp
                                                use
                                                ext
                                                jwk
                                            }
                                            refresh {
                                                exp
                                                use    
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.account = (await response.json()).data.getAccount;
                    // SET DATES
                    this.account.created_at = this.account.created_at ? new Date(this.account.created_at) : undefined;
                    this.account.updated_at = this.account.updated_at ? new Date(this.account.updated_at) : undefined;
                    this.account.expires_at = this.account.expires_at ? new Date(this.account.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.account.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * ENROLLMENTS
         */
        async loadEnrollments(all) {
            this.loading = 'Loading';
            try {

                // LIST ENROLLMENTS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEnrollments($filter: TableEnrollmentFilterInput, $limit: Int) {
                                listEnrollments(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        factor
                                        score
                                        status
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.enrollments = (await response.json()).data.listEnrollments;
                    // ADD REFRESH DATE
                    this.enrollments.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.enrollments.nextToken) {
                        if (all) {
                            return this.nextEnrollments(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        async nextEnrollments(all) {
            this.loading_more = true;
            try {

                // LIST ENROLLMENTS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEnrollments($filter: TableEnrollmentFilterInput, $limit: Int, $nextToken: String) {
                                listEnrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        factor
                                        score
                                        status
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.enrollments.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const enrollments = (await response.json()).data.listEnrollments;
                    // ADD NEW ENROLLMENTS
                    for (const enrollment of enrollments.items) {
                        this.enrollments.items.push(enrollment);
                    }
                    // NOTIFY MORE AVAILABLE
                    if (enrollments.nextToken) {
                        this.enrollments.nextToken = enrollments.nextToken;
                        if (all) {
                            return this.nextEnrollments(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * CONSENTS
         */
        async listConsents() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int) {
                                listConsents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.consents = (await response.json()).data.listConsents;
                    // ADD TYPE
                    for (const consent of this.consents.items) {
                        consent.type = 'consent';
                    }
                    // ADD REFRESH DATE
                    this.consents.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        async nextConsents() {
            if (!this.consents.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int, $nextToken: String) {
                                listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.consents.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const consents = (await response.json()).data.listConsents;
                    // ADD NEW CONSENTS
                    for (const consent of consents.items) {
                        consent.type = 'consent';
                        this.consents.items.push(consent);
                    }
                    // NOTIFY MORE AVAILABLE
                    if (consents.nextToken) {
                        this.consents.nextToken = consents.nextToken;
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * PERMISSIONS
         */
        async listPermissions() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int) {
                                listPermissions(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.permissions = (await response.json()).data.listPermissions;
                    // ADD TYPE
                    for (const permission of this.permissions.items) {
                        permission.type = 'permission';
                    }
                    // ADD REFRESH DATE
                    this.permissions.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.permissions.nextToken) {
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        async nextPermissions() {
            if (!this.permissions.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int, $nextToken: String) {
                                listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.permissions.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const permissions = (await response.json()).data.listPermissions;
                    // ADD NEW PERMISSIONS
                    for (const permission of permissions.items) {
                        permission.type = 'permission';
                        this.permissions.items.push(permission);
                    }
                    // NOTIFY MORE AVAILABLE
                    if (permissions.nextToken) {
                        this.permissions.nextToken = permissions.nextToken;
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * RULES
         */
         async listRules() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listRules($filter: TableRuleFilterInput, $limit: Int) {
                                listRules(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.rules = (await response.json()).data.listRules;
                    // ADD TYPE
                    for (const rule of this.rules.items) {
                        rule.type = 'rule';
                    }
                    // ADD REFRESH DATE
                    this.rules.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.rules.nextToken) {
                        this.$emit('alert', 'More rules are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        async nextRules() {
            if (!this.rules.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listRules($filter: TableRuleFilterInput, $limit: Int, $nextToken: String) {
                                listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.permissions.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const rules = (await response.json()).data.listRules;
                    // ADD NEW RULES
                    for (const rule of rules.items) {
                        rule.type = 'rule';
                        this.rules.items.push(rule);
                    }
                    // NOTIFY MORE AVAILABLE
                    if (rules.nextToken) {
                        this.rules.nextToken = rules.nextToken;
                        this.$emit('alert', 'More rules are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>